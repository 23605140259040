import { AbilityBuilder, Ability } from '@casl/ability'

export const AppAbility = Ability

const defineRulesFor = (auth/*, subject*/) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility)
  
  if (auth.userIsLoggedIn) {
    if (auth.userIsAdmin) {
      can('manage', 'all')
    } else if (auth.userIsManager) {
      can(['create', 'update', 'delete'], 'User', { 'roles.name': { $in: ['customer'] }})
      can('update', 'Configuration')
      can('update', 'MobileDevice')
      can(['read', 'revoke'], 'Event')
    }
  }

  return rules
}

const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export const buildAbilityFor = (auth/*, subject*/) => {
  return new AppAbility(defineRulesFor(auth/*, subject*/), {
    detectSubjectType: object => object._type
  })
}